import Vue from 'vue'
import Vuex from 'vuex'
import Mood from './module/Mood'
import History from './module/History'


Vue.use(Vuex)

const store = new Vuex.Store({
  namespaced: true,
  modules: {
    Mood,
    History
  }
});


export default store

