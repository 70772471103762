<template>
    <router-view />
</template>

<script>
export default {
    name: 'App',
    created() {
        window.Telegram.WebApp.expand();
    },
};
</script>

<style lang="scss">
@import './assets/normalize.css';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');
body {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-size: 16px;
    line-height: 18px;
    font-weight: 600;
    height: 100%;
}

body,
html,
#app {
    min-height: 100vh;
    width: 100%;
    background: #fff;
}

* {
    box-sizing: border-box;
}
.container {
    width: 100%;
    max-width: 560px;
    margin: 0 auto;
}

.van-button--primary {
    // background: #000 !important;
    // border-color: #000 !important;
}

.van-picker-column__item {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: normal;
    /* or 72% */

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.02em;

    color: #c6b3a3 !important;
}
.van-ellipsis {
}

.page {
    background-color: #f5eee8;
    height: 100%;
    padding-top: 58px;
    min-height: 100vh;
}
::-webkit-scrollbar {
    width: 0;
}
.van-circle__layer {
    stroke: #f5eee8f0 !important;
}
</style>
