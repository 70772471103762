export default {
	namespaced: true,

	state: {
		mood: {

		}
	},

	getters: {
		mood: (state) => state.mood,
	},


	mutations: {
		set_mood: (state, payload) => {
			state.mood = payload;
		},
	}
}