import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Home',
      component: () => import('@/views/Home.vue')
    },
    {
      path: '/calendar',
      name: 'Calendar',
      component: () => import('@/views/Calendar.vue')
    },
    {
      path: '/time',
      name: 'Time',
      component: () => import('@/views/Time.vue')
    },
    {
      path: '/new',
      name: 'New',
      component: () => import('@/views/New/')
    },
    {
      path: '/history',
      name: 'History',
      component: () => import('@/views/History.vue')
    },
    {
      path: '/chart',
      name: 'Chart',
      component: () => import('@/views/Chart/')
    }
  ]
})
